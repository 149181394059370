.produto--line{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item--last{
    display: flex;
    flex: 1;
    margin-left: 15px;
}


.item--price{
    display: flex;
    margin-right: 15px;
}