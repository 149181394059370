.content-restaurants{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    
}

.loja-content{
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    align-items: center;    
    padding: 0px;
    margin: 0px;
}

.ant-card-body{
    margin: 0px;
    padding: 0px;
}

.loja-lines{
    display: flex;
    flex: 1;
    flex-direction: column;

    flex-wrap: wrap;
    min-width: 0;
    padding-left: 10px;
}

.loja-line{
    display: flex;
    width: 100%;
    align-items: center;

}

.loja-line-fantasia{
    display: flex;
    width: 100%;
    font-weight: 600;
}

.loja-line-fantasia p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
}
 

 