.default-header-appbar{
    display: flex;
    flex-direction: row;
    padding-top: 7px;
    padding-bottom: 7px;
    align-items:center ;    
    justify-content: space-between;
    color: white;
    font-size: x-large; 
    height: 60px;
}



.appbar-options{
 padding-right: 20px;
}

.sidebar-list-item{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.sidebar-list-divider{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #4c4ce4;
    color: #4c4ce4;;
}

.div-invite-code{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}

.div-invite-code-item{
    display: flex;
    justify-content: center;
}

.sidebar-list-avatar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}