.home-footer{
    background-color: rgb(78, 77, 77);
    margin-top: 50px;   
    justify-content: center;
}

.home-footer-itens{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.home-footer-item{
    display: flex;
    justify-content: center;
    flex-direction: row;
    color: white;
    font-family: Raleway,sans-serif;
    margin-top: 15px;
    text-align: center;

}

.home-footer-item-2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-family: Raleway,sans-serif;
    margin-top: 15px;
    text-align: center;

}