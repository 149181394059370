.root {
    height: 100vh;
  
  }
  
  .image {
    background-repeat: no-repeat;
    background-image: url("https://programaengfood.com.br/images/banner_portal.jpg");
    background-size: cover;
    background-position-x: center;
  }
  
  .paper {
    margin: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avatar {
    margin: 2;
  }
  
  .form {
    width: 100%;
    margin-top: 10;
    margin: 50px;
  }
  
  .submit {
    margin: 5;
    margin-top: 15px;
  }
  
  
  
  /* nova tela de login */
  .plano-de-fundo {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-repeat: no-repeat;
   /* background-color: #1f41a9; */
 /*   background-image: url("../../img/BG.svg"); */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .plano-de-fundo-header {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-repeat: no-repeat;
   /* background-color: #1f41a9; */
   /* background-image: url("../../img/BG.svg"); */
    display: flex;
    
  }
  
  /*
  .plano-de-fundo div div{
    color: whitesmoke;
    text-align: center;
    font-size: 0.9em;
  }
  
  .plano-de-fundo div strong{
    color: white;
    margin-bottom: '25px';
    font-size: 1.5em;
    text-align: center;
    justify-content: center;
  }
  
  */
  
  .content {
    /*@include display-flex();*/
   /* @include align-items-center(); */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 25vh;
    text-align: center;
  }
  
  
  .input-text{
    border: 1px solid #fff;
    border-radius: 10px;
    height: 50px;
    background: none;
    width: -webkit-fill-available;
  }
  
  
  
  .form-login {
    display: -ms-grid;
    display: grid;
    margin: 40px;
    width: 433px;
  
  }
  