.div-info-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.div-info-header{
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: #2c2c6c;
    justify-content: center;
}


.div-info-tipos{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px;
}


.tag-infos-tipo{
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div-info-text{
    display: flex;
    justify-content: center;
}


.div-bairros-list{
    margin-top: 15px;
    margin: 15px;
}

.div-bairros-list-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.div-bairros-list-item-descricao{
    font-size: 15;
    color: darkgreen;
    text-transform: uppercase;
    
}

.div-bairros-list-item-valor{
    font-size: 15;
    color: #2c2c6c;
}