  

  
 
  
  
  
  
  .sign-in {
    color: #282c34;
    background: white;
    max-width: 400px;
    margin: 0 auto;
  }
  
  
  
  
  .message {
    display: flex;
    align-items: center;
  }
  
  
  .sent {
    flex-direction: row-reverse;
  }
  