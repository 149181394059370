.address--buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 20px;
}

.address--buttons-item{
    display: flex;
    justify-content: center;
}