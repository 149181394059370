.UserComponent-main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 5px;
}

.UserComponent-avater{
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserComponent-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.UserComponent-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
}

.UserComponent-icon{
    display: flex;
}