.plano-de-fundo-cardapio {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-repeat: no-repeat;
    background-color: #ffffff;
    background-image: url("../../img/ilustracion/cardapio-digital2.png");
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}



.nav-categorias {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    scrollbar-width: none;
}

.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

ul{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;


}

#secao-categories {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1051;
    margin: 0 -15px;
    background: #fff;
}