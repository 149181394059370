.pedido-detail-item-right{
    display: flex;
    justify-content: flex-end;

}

.divider-orders{
    margin-top: 4px;
    margin-bottom: 4px;

}

.andamento-header{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.div-center{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}


.div-centered{
    display: flex;
    justify-content: center;
}

.div-centered-buttons{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 0;
}

.div-centered-subtitle{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}