*{
    box-sizing: border-box;
}

body{
    background-color: #d2dbdc;
    margin: 0;
    font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, 'Lucida Grande',Arial;
}

.app-window{
    display: flex;
    min-height: 100vH;
    background-color: #ededed;
    min-width: 0;
}

.sidebar{
    width: 35%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ddd;
    background-color: #191970;
}

.contentarea{
    background-color: #fff;
    flex: 1;
    padding: 5px 15px;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.default-main{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: whitesmoke;
}

.default-header{
    display: flex;
    flex-direction: row;
    padding-top: 7px;
    padding-bottom: 7px;
    align-items:center ;
    background-color: #4c4ce4;
    justify-content: center;
    color: white;
    font-size: x-large;
}

.default-content{
    background-color: white;
    min-height: 100px;
    width: 85%;
    margin: 0 auto;
    margin-top: 20px;
}

.default-content-mobile{
    background-color: white;
    min-height: 100px;
    margin-top: 20px;
}