.container-outlet {
  display: grid;
  grid-template-rows: 50px 1fr 30px 5px;
  height: 100vh;
  background-color: white;
}


.container-outlet main {
  overflow-y: auto;
  background-color: white;
  padding: 10px;
  padding-top: 62px;
}


.container-outlet footer {
  background: white;
  position: sticky;
  bottom: 0;
}


.styles.header {
  background-color: navy; 
}



.plano-de-fundo {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  /*background-color: #1f41a9;*/
  /*background-image: url("../../img/BG.svg");*/
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
 /* background-color: navy; */

}


.plano-de-fundo-login {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  /*background-color: #1f41a9;*/
  /*background-image: url("../../img/BG.svg");*/
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: navy;

}

.ant-drawer-header {
 /* background-color: royalblue; */
  position: sticky;
  top: 0;
}