.rest-div-image {
    margin-top: 25px;
    background-size: 100%;
    max-height: 280px;
    height: 280px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rest-div-image2 {
    margin-top: 25px;
    background-size: 100%;
    max-height: 280px;
    height: 280px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../img/ilustracion/desenho.jpg');
    display: flex;
    flex-direction: column;
    justify-content: space-between;


}

.div-name-rest {
    font-weight: 600;
    color: #4c4ce4;
}

.div-name-rest-font-mobile {
    font-size: large;
}

.div-name-rest-font-desktop {
    font-size: x-large;
}

.div-avatar-rest {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: -45px;
    float: right;
    border-radius: 10px;
}


.div-avaliacao-rest {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 30px;
    align-items: center;
    float: left;
    height: 90px;

}

.div-avatar-rest-itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row-info-rest {
    color: gray;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
}

.row-info-rest-itens {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    flex: 1;
    margin: 5px;
}

.row-info-rest-item-header {
    font-size: 17px;
    color: gray;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
}

.div-modal-bairros-footer {
    display: flex;
    justify-content: center;
}

.div-content-rest-cardapio {
    margin-top: 0px;
}

.col-category {
    font-weight: 600;
    font-size: large;
}



.col-produto {
    font-size: medium;
    font-weight: normal;
}

.produto-content {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    align-items: center;
    padding: 0px;
    margin: 0px;
}

.ant-card-body {
    margin: 0px;
    padding: 0px;
}


.produto-lines {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 0;
    padding-left: 10px;
    height: 120px;
}

.produto-line {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0px;
}

.produto-line-name {
    display: flex;
    width: 100%;
    font-weight: 600;
}

.produto-line-name p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
    color: #515050;
    font-weight: 700;
    font-size: 1rem;
    padding: 5px;
    
}

.paragraph-produto-line {
    margin: 0px;
    padding: 0px;
    line-height: normal;
    margin-top: -12px;
    align-items: flex-start;
    color: gray;
}

.renderFooterCupom-buttons {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.renderFooterCupom-buttons-item {
    display: flex;
    justify-content: center;
}


.card-content-itens {
    display: flex;
    flex-direction: row;
}

.card-content-item {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.card-content-item-img {
    display: flex;
    align-items: center;
}

.card-payment-content {
    margin: 5px;
    border-radius: 5px;
}

.card-content-item-line {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

}

.modal-content-troco {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
}

.wrapper {
    max-height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.699);
    display: flex;
    overflow-x: auto;
    align-items: center;
    margin-bottom: -30px;
    margin-top: 0px;
    background-color: white;
    padding-left: 20px;
}

.wrapper::-webkit-scrollbar {
    width: 0;
}



 .item {
    height: 60px;
    text-align: center;
    background-color: white;
    color: rgb(94, 92, 92);
    font-weight: bold;
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    margin-right: 40px;
}

