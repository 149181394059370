.totaisComponent-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.totaisComponent-line-col1{
    display: flex;
    align-items: center;
    flex-direction: row;
}