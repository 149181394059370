.home-main{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: whitesmoke;
}

.home-header{
    display: flex;
    flex-direction: row;
    padding-top: 7px;
    padding-bottom: 7px;
    align-items:center ;
    background-color: #4c4ce4;
    justify-content: center;
    color: white;
    font-size: x-large;
}

.home-content{
    background-color: white;
    min-height: 100px;
    width: 80%;
    margin: 0 auto;
}

.home-content-mobile{
    background-color: white;
    min-height: 100px;
}

.home-div-image{
    margin-top: 25px;
    background-size: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-image: url('../../img/ilustracion/background-food-2.jpg'); 
    display: flex;
    align-items:center ;
    flex-direction: column;
    justify-content: center;
    
}

.home-div-image-text{
    display: flex;    
    font-family: Georgia, 'Times New Roman', Times, serif ;
    width: 50%;
    text-align: center;
    color: #fff;
    flex: 1;
    align-items:center ;
    align-self:center ;
    justify-content: center;
}




.home-div-image-buttons{
    padding: 5px;
    justify-content: center;
    display: flex;
    margin-top: 5px;
    margin-bottom: 15px;
    flex-direction: column;
}

