*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: sans-serif;
}
.section-1 {
	/*background: url('../../img/ilustracion/background-food-2.jpg'); */
	background-size: cover;
	height: 25vh;
	background-position: center center;
    z-index: 10000;
}
header {
	display: flex;
	padding: 30px;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	transition: 500ms;
    z-index: 10000;
    width: 100%;   
    
}
nav {
	margin-right: 70px;
}
nav a {
	display: inline-block;
	margin-left: 10px;
	font-size: 15px;
	text-decoration: none;
	font-family: sans-serif;
	color: #eee;
	text-transform: uppercase;
	border-bottom:  3px solid transparent;
	padding: 10px 1px;
	transition: 200ms;
}
.active {
	border-color: #00B3FF;
}
nav a:hover {
	border-color: #00B3FF;
}
.logo {
	flex: 1;
	font-size: 20px;
	font-family: sans-serif;
	margin-left: 10px;
	color: #fff;
}
.bgc {
	padding: 10px;
}
.bgc .logo {
	font-size: 20px;
	color: #000;
}
.bgc nav a {
	color: #222;
}



.section-2 {
	padding: 0px;
	/* padding-left: 20px; */
}
.section-2 h1 {
	padding: 0px;
	/*font-size: 40px; */
}
.section-2 p {
	padding: 0px;
	/*line-height: 1.8em;
	font-size: 18px; */
}
@media (max-width: 769px){
	header {
		flex-direction: row;
		padding: 20px;
	}
	nav {
		padding: 15px;
		margin: 0px;
	}
}


.section-3 {	
	display: flex;	
	position: fixed;
    z-index: 9999999;
    width: 100%;  
}