.notification-line{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.notification-line-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:initial;
}

