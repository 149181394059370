.main-cupons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-cupons-title{
    font-size: medium;
    font-weight: 600;
    justify-content: center;

}

.cupom-row-1{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.cupom-row-item{
    display: flex;
    justify-content: center;
    align-items: center;

}