.avaliacoes-list{
    display: flex;
    flex-direction: row;
    width: 100%;

}

.avaliacoes-list-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
    width: 100%;
}



.avaliacoes-list-item-column{
    display: flex;
    flex-direction: column;
}

.avaliacoes-item-rigth{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}