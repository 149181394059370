.grupo-item-right{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}


.grupo--item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.grupo--item--footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.div-sabores-pattern{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ant-drawer-body{
    display: flex !important;
    padding: -24px !important;
}


